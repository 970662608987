import {OAuthConfig} from './@types/global';

export const QDD_IDENTITY_SERVER_BASE_URL = 'api/qff/identity';

export const SESSION_STORAGE_KEY = 'oauth_state';
export const ACCESS_TOKEN = 'access_token';
export const LSL_AUTH_COOKIE_EXPIRE_IN_DAYS = 365;

export const TOKEN_EXCHANGE_FAIL_MESSAGE = 'Authentication got failed.';

export enum OAUTH_EVENTS {
  INIT = 'oAuthInitCompleted',
  TOKEN_EXCHANGE_SUCCESS = 'oAuthTokenExchangeSuccess',
  TOKEN_EXCHANGE_FAILURE = 'oAuthTokenExchangeFailure'
}

export enum COOKIES {
  LSL_AUTH_DATA = 'lsl_auth_data',
  QLFF_WL_SESS = 'qlff_wl_sess'
}

export enum SESSION_OBJS {
  STATE = 'state',
  CODE_VERIFIER = 'codeVerifier',
  LOGIN_SUCCESS_REDIRECT = 'loginSuccessRedirect'
}

export enum QUERY_PARAMS {
  STATE = 'state',
  CODE = 'code'
}

export const QFF_OAUTH_ACCOUNTS_CLIENT_ID = 'qcomLogin';

export const mockOauthConfig: OAuthConfig = {
  isEnabled: true,
  successRedirectUrl: ''
};

export const mockLoginDialog = {
  model: {
    businessRewardsLinkNewAccountUrl:
      'https://aem-publish-dev-a243-05-titan-1.qcpaws.qantas.com.au/au/en/frequent-flyer/business-rewards-connect.html',
    businessRewardsLoginUri: 'https://www.qantasbusinessrewards.com/login',
    dologinUri: '/au/en/frequent-flyer/my-account.html',
    enableOauthLogin: true,
    enableSocialLogin: true,
    environment: 'SIT',
    frequentFlyerBookingUri: '/au/en/frequent-flyer/my-account/bookings.html',
    frequentFlyerHomeUri: '/au/en/frequent-flyer/my-account.html',
    frequentFlyerJoinUri: '/au/en/frequent-flyer/discover-and-join/join.html',
    frequentFlyerLoginUri: '/au/en/frequent-flyer/my-account.html',
    frequentFlyerPointsUri:
      '/au/en/frequent-flyer/my-account/points-activity.html',
    frequentFlyerProfileUri: '/au/en/frequent-flyer/my-account/profile.html',
    frequentFlyerStatusUri:
      'https://www.qantas.com/travel/airlines/flight-search/global/en',
    includeBusinessRewardsLogin: true,
    includeLoginRibbon: true,
    loginBundleJs:
      'https://cdn.sit.qantasloyalty.com/assets/widgets/login/v2/login.bundle.js',
    loginCommonJs:
      'https://cdn.sit.qantasloyalty.com/assets/widgets/login/v2/common.bundle.js',
    loginInitJs:
      'https://cdn.sit.qantasloyalty.com/assets/widgets/login/v2/init.bundle.js',
    loginLoadingPageUrl: `${process.env.OAUTH_LOGIN_LOADING_URL}`,
    sessionCheckUrl:
      'https://www-staging.qantas.com/fflyer/dyns/amadeus/sessionAvailableCheck',
    ssologinUri: 'https://www.qantas.com/fflyer/do/dyns/ssologin',
    ssologoutUri: 'https://www.qantas.com/fflyer/dyn/ssologout',
    widgetCssUrl:
      'https://cdn.sit.qantasloyalty.com/assets/widgets/login/v2/login.bundle.css',
    oauthLoginSuccessRedirectUrl: window.location.href
  },
  ui: {
    businessRewardsLabel: 'Business Rewards',
    businessRewardsLinkNewAccountLabel: 'Connect your account',
    businessRewardsLoginLabel: 'Qantas Business Rewards login',
    frequentFlyerBookingsLabel: 'My bookings',
    frequentFlyerHomeLabel: 'My account',
    frequentFlyerLoginLabel: 'Qantas Frequent Flyer login',
    frequentFlyerPointsLabel: 'My activity statement',
    frequentFlyerProfileLabel: 'My profile',
    frequentFlyerStatusLabel: 'Make a Reward booking',
    ribbonCloseLabel: 'Close',
    ribbonLoginLabel: 'Log in'
  }
};
export const SECURE_CONTENT_PATH_PATTERN_ARRAY = [
  /.*\/frequent-flyer\/my-account.*\.html/,
  /.*\/frequent-flyer\/your-account?.*\.html/,
  /.*\/my-account.html/,
  /.*\/offer\/r\/\d{4}\/.*\.html/
];

export const DEFAULT_REGION = '/au/en';
